import React from 'react';

// Import all components

const Onpay = React.lazy(() => import('./pages/Onpay/index'));
const PageLogin = React.lazy(() => import('./pages/PageLogin'));
const Components = React.lazy(() => import('./pages/Components'));

const routes = [

    // public Routes
    { path: '/', component: Onpay },
    { path: '/login', component: Onpay, data: {modal: true}},
    { path: '/components', component: Components },
    

];

export default routes;