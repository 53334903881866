import React, { Component } from 'react';
import Layout from './components/Layout/';
import { Route, Switch, BrowserRouter as Router, withRouter } from 'react-router-dom';

// Import Css
import './Apps.scss';
import './css/materialdesignicons.min.css';

// Include Routes 
import routes from './routes';

// Root Include
const Onpay = React.lazy(() => import('./pages/Onpay/index'));
const PageError = React.lazy(() => import('./pages/PageError'));
const tawkTo = require("tawkto-react");

function withLayout(WrappedComponent, data) {
  return class extends React.Component {  
    render() {
      return <Layout>
        <WrappedComponent data={data}></WrappedComponent>
      </Layout>
    }
  };
}

class App extends Component {


  componentDidMount(){
      tawkTo("5736ab807a3ac52f0fa099b9")
  }

  render() {

    return (
      <React.Fragment>  
      <Router>
          <React.Suspense fallback={<div></div>}>
            <Switch>
            {routes.map((route, idx) =>
                <Route path={route.path} exact component={withLayout(route.component, route.data)} key={idx} />
            )}
            
            <Route component={PageError} />
            </Switch>
          </React.Suspense>


        </Router>
      </React.Fragment>
    );
  }
}

export default withRouter(App);