import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Link as LinkScroll, animateScroll as scroll } from "react-scroll";

// import images
import fpx from '../../images/onpay/fpx.png';
import masterCard from '../../images/onpay/mc.png';
import paypal from '../../images/onpay/pp.png';
import visa from '../../images/onpay/visa.png';

import logo_alt from '../../images/onpay/logo-alt.png';
import google_play from '../../images/onpay/gplay_badge.png';
import apps_store from '../../images/onpay/apps_badge.svg';

class Footer extends Component {

    constructor(props) {
        super(props);
        this.state = { };
    }

    render() {
        return (
            <React.Fragment>
                <footer className="footer">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-4 col-12 mb-0 mb-md-4 pb-0 pb-md-2">
                                <Link className="logo-footer" to="#"><img style={{height: 30}} src={ logo_alt } alt="OnPay"/></Link>
                                <p className="mt-4">OnPay menyediakan satu penyelesaian yang sesuai untuk pemilik atau usahawan bisnes online untuk menguruskan bisnes dan jualan mereka dengan mudah.</p>
                                <ul className="list-unstyled social-icon social mb-0 mt-4">
                                    <li className="list-inline-item"><a href="https://www.facebook.com/onpaysb/" className="rounded mr-1"><i className="mdi mdi-facebook" title="Facebook"></i></a></li>
                                    <li className="list-inline-item"><a href="https://twitter.com/MyOnPay/" className="rounded"><i className="mdi mdi-twitter" title="Twitter"></i></a></li>
                                </ul>
                            </div>
                            
                            <div className="col-lg-2 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                                <h4 className="text-light footer-head">Navigasi</h4>
                                <ul className="list-unstyled footer-list mt-4">
                                    <li><LinkScroll to="main" className="text-foot"><i className="mdi mdi-chevron-right mr-1"></i> Utama</LinkScroll></li>
                                    <li><LinkScroll to="users" className="text-foot"><i className="mdi mdi-chevron-right mr-1"></i> Pengguna</LinkScroll></li>
                                    <li><LinkScroll to="about" className="text-foot"><i className="mdi mdi-chevron-right mr-1"></i> Info</LinkScroll></li>
                                    <li><LinkScroll to="features" className="text-foot"><i className="mdi mdi-chevron-right mr-1"></i> Fungsi</LinkScroll></li>
                                    <li><LinkScroll to="packages" className="text-foot"><i className="mdi mdi-chevron-right mr-1"></i> Pakej</LinkScroll></li>
                     
                                </ul>
                            </div>
                            
                            <div className="col-lg-3 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                                <h4 className="text-light footer-head">Pautan</h4>
                                <ul className="list-unstyled footer-list mt-4">
                                    <li><a href="/register" className="text-foot"><i className="mdi mdi-chevron-right mr-1"></i> Daftar Akaun</a></li>
                                    <li><Link to="/login" className="text-foot"><i className="mdi mdi-chevron-right mr-1"></i> Log Masuk</Link></li>
                                    <li><a href="/faq" className="text-foot"><i className="mdi mdi-chevron-right mr-1"></i> FAQ</a></li>
                                    <li><a href="https://tutorial.onpay.my/" className="text-foot"><i className="mdi mdi-chevron-right mr-1"></i> Tutorial</a></li>
                                    <li><a href="/marketplace" className="text-foot"><i className="mdi mdi-chevron-right mr-1"></i> Marketplace</a></li>
                                    <li><a href="/agent" className="text-foot"><i className="mdi mdi-chevron-right mr-1"></i> Affiliate</a></li>
                                    <li><a href="/policy" className="text-foot"><i className="mdi mdi-chevron-right mr-1"></i> Polisi</a></li>
                                    <li><a href="https://blog.onpay.my/" className="text-foot"><i className="mdi mdi-chevron-right mr-1"></i> Blog</a></li>
                                    <li><a href="https://status.onpay.my/" className="text-foot"><i className="mdi mdi-chevron-right mr-1"></i> Status Sistem</a></li>
                                </ul>
                            </div>
                            <div className="col-lg-3 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                                <h4 className="text-light footer-head">Aplikasi Mudah Alih</h4>
                                <p className="mt-4">Muat turun aplikasi OnPay di telefon pintar anda.</p>
                                <form>
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="form-group position-relative text-center">
                                                <a href="https://play.google.com/store/apps/details?id=my.onpay.app" target="_blank"><img src={google_play} className="mr-2" title="Google Play" alt="" style={{height: 70}}/></a>
                                                <a href="https://apps.apple.com/us/app/onpay/id1465216952" target="_blank"><img src={apps_store} className="mr-2" title="Apps Store" alt="" style={{height: 53}}/></a>
                                            </div>
                                        </div>

                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </footer>
                <hr />
                <footer className="footer footer-bar">
                    <div className="container text-center">
                        <div className="row align-items-center">
                            <div className="col-sm-8">
                                <div className="text-sm-left">
                                    <p className="mb-0">&copy; 2014 &ndash; {new Date().getFullYear()}  OnPay Solutions Sdn Bhd. Dibangunkan dengan cinta di Kelantan &amp; Kuala Lumpur.</p>
                                </div>
                            </div>
                            <div className="col-sm-4 mt-4 mt-sm-0 pt-2 pt-sm-0">
                                <ul className="list-unstyled payment-cards text-sm-right mb-0">
                                    <li className="list-inline-item mr-1"><Link to="#"><img src={fpx} className="mr-2" style={{height: 20}} title="FPX" alt="" /></Link></li>
                                    <li className="list-inline-item mr-1"><Link to="#"><img src={visa} style={{height: 14}} className="mr-2" title="Visa" alt="" /></Link></li>
                                    <li className="list-inline-item mr-1"><Link to="#"><img src={masterCard} style={{height: 18}} className="mr-2" title="Master Card" alt="" /></Link></li>
                                    <li className="list-inline-item mr-1"><Link to="#"><img src={paypal} style={{height: 16}} title="Paypal" alt="" /></Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </footer>
            </React.Fragment>
        );
    }
}

export default Footer;
