import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { withRouter } from "react-router";

import { Link as LinkScroll, animateScroll as scroll } from "react-scroll";

import logo from '../../images/onpay/logo.png';

class Topbar extends Component {

    constructor(props) {
        super(props);
        this.state = { 
            isOpen : false, 
            page : false, 
            landing : false,
            docs : false,
            new :false,
            utility : false,
            user : false,
            work : false,
            blog : false,
            parent: props.parent,
            carr : false };
        this.toggleLine = this.toggleLine.bind(this);
    }

    toggleLine() {
        this.setState(prevState => ({  isOpen: !prevState.isOpen }));
    }

    componentDidMount() {
         var matchingMenuItem = null;
         var ul = document.getElementById("top-menu");
         var items = ul.getElementsByTagName("a");
         for (var i = 0; i < items.length; ++i) {
             if (this.props.location.pathname === items[i].pathname) {
                 matchingMenuItem = items[i];
                 break;
             }
         }
         if (matchingMenuItem) {
            this.activateParentDropdown(matchingMenuItem);
         }
     }
 
     activateParentDropdown = (item) => {
          const parent = item.parentElement;
          if (parent) {
              parent.classList.add('active'); // li
              const parent1 = parent.parentElement;
              parent1.classList.add('active'); // li
              if (parent1) {
                  const parent2 = parent1.parentElement;
                  parent2.classList.add('active'); // li
                  if (parent2) {
                    const parent3 = parent2.parentElement;
                    parent3.classList.add('active'); // li
                    if (parent3) {
                        const parent4 = parent3.parentElement;
                        parent4.classList.add('active'); // li
                 }
             }
         }
     }
    }

    render() {
        return (
            <React.Fragment>
                <header id="topnav" className="defaultscroll sticky">
                    <div className="container">
                        <div>
                            <Link className="logo" to="/"><img style={{height: 30}} src={ logo }/></Link>
                        </div>
                        <div className="buy-button">
                            <Link to="/login" className="btn btn-outline-primary mr-2">Log Masuk</Link>
                            <a href="/register" className="btn btn-primary">Daftar Akaun</a>
                        </div>
                        <div className="menu-extras">
                            <div className="menu-item">
                                <Link to="#" onClick={ this.toggleLine } className={this.state.isOpen ? "navbar-toggle open" : "navbar-toggle" } >
                                    <div className="lines">
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                    </div>
                                </Link>
                            </div>
                        </div>

                        <div id="navigation" style={{ display : this.state.isOpen ? "block" : "none" }}>
                            <ul className="navigation-menu" id="top-menu">
                                <li><LinkScroll to="main" activeClass="active" smooth={true}>Utama</LinkScroll></li>
                                <li><LinkScroll to="users" activeClass="active" smooth={true}>Pengguna</LinkScroll></li>
                                <li><LinkScroll to="about" activeClass="active" smooth={true}>Info</LinkScroll></li>
                                <li><LinkScroll to="features" activeClass="active" smooth={true}>Fungsi</LinkScroll></li>
                                <li><LinkScroll to="packages" activeClass="active" smooth={true}>Pakej</LinkScroll></li>
                                <li><a href="https://tutorial.onpay.my">Tutorial</a></li>
                            </ul>
                        </div>
                    </div>
                </header>
            </React.Fragment>
        );
    }
}

export default withRouter(Topbar);